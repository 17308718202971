@keyframes Rotate {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(540deg);
    }
  }
  #loader{
      width: 100%;
      height: 100%;
      position: fixed;
      top:0;
      z-index: 999;
      background: #fff;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      
      visibility: hidden;
      opacity: 1;
      transition: 0.5s ease;
      
      &.loader{
          transition:none !important;
          visibility:visible;
          &:after{
              content: "";
              border: 4px solid #ccc;
              // Zmienić na poczatku pracy na kolor z base:
              border-top: 4px solid #21201f;
              border-radius: 100%;
              height: 60px;
              width: 60px;
              position: absolute;
              transition: .02s ease-out;
              animation: Rotate .7s infinite cubic-bezier(.2,.8,.8,.2); 
          }	
      }
  }